import * as Icons from './icons';
export { Icons };

export { default as Button } from './components/button';
export { default as ButtonCircle } from './components/button-circle';
export { default as Heading } from './components/heading';
export { default as Text } from './components/text';
export { default as Link } from './components/link';
export { default as Input } from './components/input';
export { default as Label } from './components/label';
export { default as Image } from './components/image';
export { default as Small } from './components/small';
export { default as Absolute } from './components/absolute';
export { default as BackgroundImage } from './components/background-image';
export { default as Select } from './components/select';
export { default as Textarea } from './components/textarea';

import InstagramCardIcon from 'components/cards/icons/instagram';
import LinksCardIcon from 'components/cards/icons/links';
import ProfileCardIcon from 'components/cards/icons/profile';
import SimpleCardIcon from 'components/cards/icons/simple';
import { ImageGalleryIcon, MenuIcon, TapBioWordMark, YouTubeIcon } from 'components/icons';
import MailingListIcon from 'components/icons/social-email';
import StarIcon from 'components/icons/star';
import { Text } from 'components/web-ui';
import routes from 'config/routes';
import React from 'react';
import * as Redux from 'react-redux';
import { Link } from 'react-router-dom';
import { SortableHandle } from 'react-sortable-hoc';
import { hideMoreMenu } from 'store/modules/more-menu';
import { css } from 'styled-components';
import styled from 'styled-components/macro';
import ToggleHiddenButton from './toggle-hidden-button';

const ListItem = styled.div`
  border-top: 1px solid #e5e5e7;
  border-bottom: 1px solid #e5e5e7;
  margin-top: -1px;
  padding-right: 15px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu;
`;

const immovableItemStyles = css`
  opacity: 0.5;
`;

const Layout = styled.div`
  padding: 0.3rem 0;
  display: flex;
  align-items: center;
  ${(p) => !p.moveable && immovableItemStyles};
`;

const Title = styled(Link)`
  flex: 1;
  line-height: 1.1;
  margin-left: 0.5rem;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

const Controls = styled.div`
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  opacity: ${(p) => (p.disabled ? 0 : 1)};
  cursor: ${(p) => (p.disabled ? 'initial' : 'ns-resize')};
`;

const TitleCased = styled(Text)`
  text-transform: capitalize;
`;

const CardIcon = styled.div`
  width: 36px;
  height: 36px;
  padding: ${(p) => (p.kind === 'tap.bio' ? '0' : '8px')};
  background-color: #e6e6e6;
  color: #341650;
  color: ${(p) => !p.moveable && '#333333'};
  border-radius: 50%;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div``.withComponent((props) => {
  switch (props.kind) {
    case 'instagram':
      return <InstagramCardIcon {...props} />;

    case 'simple':
      return <SimpleCardIcon {...props} />;

    case 'links':
      return <LinksCardIcon {...props} />;

    case 'profile':
      return <ProfileCardIcon {...props} />;

    case 'discover':
      return <StarIcon {...props} />;

    case 'youtube':
      return <YouTubeIcon {...props} />;

    case 'tap.bio':
      return <TapBioWordMark {...props} />;

    case 'mailing-list':
      return <MailingListIcon {...props} />;

    case 'image-gallery':
      return <ImageGalleryIcon width={24} {...props} />;

    default:
      return null;
  }
});

const DragHandle = SortableHandle(() => <MenuIcon fill="#CFCFD4" />); // This can be any component you want

const renderCardTitle = (card) => {
  if (card.title.trim()) {
    return <Text>{card.title}</Text>;
  } else if (card.kind === 'mailing-list') {
    return <Text>Mailing List</Text>;
  } else if (card.kind === 'youtube') {
    return <Text>YouTube - {card.youtube.username}</Text>;
  } else if (card.kind === 'image-gallery') {
    return <Text>Image Gallery</Text>;
  }
  return <TitleCased>{card.kind + ' card'}</TitleCased>;
};

function CardListItem(props) {
  const { card, currentAccount, hideMoreMenu } = props;
  const moveable = card.kind !== 'tap.bio' && card.moveable;
  return (
    <ListItem>
      <Layout moveable={moveable}>
        <CardIcon moveable={moveable} kind={card.kind}>
          <Icon kind={card.kind} />
        </CardIcon>
        <Title
          children={renderCardTitle(card)}
          to={routes.accountCardPath(currentAccount.username, card.id)}
          onClick={hideMoreMenu}
        />
        <ToggleHiddenButton card={card} currentAccount={currentAccount} />
        <Controls disabled={!moveable}>
          <DragHandle />
        </Controls>
      </Layout>
    </ListItem>
  );
}

export default Redux.connect(
  () => ({}),
  (dispatch) => ({
    hideMoreMenu: () => dispatch(hideMoreMenu()),
  }),
)(CardListItem);

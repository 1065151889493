const staging = {
  domain: 'tap.bio',
  apiHost: 'https://staging.api.tap.bio',
  cdnHost: 'https://tap-bio-staging.imgix.net',
  landingHost: 'https://qaz.tap.bio',
  dashboardHost: 'https://qaz.dashboard.tap.bio',
  featuredProfiles: [
    { username: 'designmom7', featuredText: 'Trending' },
    { username: 'erondu', featuredText: 'Featured' },
    { username: 'goetto' },
    { username: 'gotwalt' },
    { username: 'jesseengle', featuredText: 'Featured' },
    { username: 'leighannengle' },
    { username: 'tapbio100', featuredText: 'Trending' },
    { username: 'timothyfletcher' },
  ],
  google: {
    cloudStorageMediaBucket: 'tb-staging-media',
  },
  instagram: {
    appId: '3595116747180026',
  },
  mailchimp: {
    clientId: '932449998846',
  },
  constantcontact: {
    clientId: 'kj36jz262crumhhst4gpzz8p',
  },
  stripe: {
    publishableKey: 'pk_test_0Sw9oY3CULgDl04tLMw8uPLG',
  },
  sentry: {
    publicDsn: 'https://86db851704f94003be4bc21f800fbe52@sentry.io/273460',
  },
};

export default staging;

import React from 'react';

function Arrow(props) {
  return (
    <svg width={24} height={10} viewBox="0 0 24 10" {...props}>
      <path
        d="M23.786.032l-8.702 8.702a4 4 0 0 1-5.656 0L.726.032h23.06z"
        fill="#FFF"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Arrow;

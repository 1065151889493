import React from 'react';

function LinkThick(props) {
  return (
    <svg
      width={18}
      height={22}
      viewBox="0 0 26 26"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g id="surface1">
        <path
          fill="#ffffff"
          d="M 19.625 0.15625 C 17.996094 0.234375 16.429688 0.945313 15.21875 2.15625 L 10.46875 6.90625 C 11.214844 6.15625 13.984375 6.671875 14.65625 7.34375 L 17.53125 4.46875 C 18.167969 3.832031 18.964844 3.441406 19.78125 3.40625 C 20.335938 3.378906 21.121094 3.496094 21.8125 4.1875 C 22.457031 4.832031 22.59375 5.582031 22.59375 6.09375 C 22.59375 6.949219 22.203125 7.800781 21.53125 8.46875 L 16.53125 13.5 C 15.273438 14.757813 13.359375 14.859375 12.25 13.75 C 11.617188 13.117188 10.574219 13.113281 9.9375 13.75 C 9.300781 14.386719 9.300781 15.425781 9.9375 16.0625 C 11.078125 17.203125 12.578125 17.78125 14.125 17.78125 C 15.796875 17.78125 17.503906 17.097656 18.8125 15.78125 L 23.84375 10.78125 C 25.121094 9.507813 25.84375 7.804688 25.84375 6.09375 C 25.84375 4.503906 25.242188 2.992188 24.125 1.875 C 22.929688 0.679688 21.324219 0.078125 19.625 0.15625 Z M 11.875 8.21875 C 10.203125 8.21875 8.46875 8.90625 7.15625 10.21875 L 2.15625 15.21875 C 0.878906 16.492188 0.15625 18.195313 0.15625 19.90625 C 0.15625 21.496094 0.757813 23.007813 1.875 24.125 C 3.070313 25.320313 4.675781 25.921875 6.375 25.84375 C 8.003906 25.765625 9.570313 25.054688 10.78125 23.84375 L 15.53125 19.09375 C 14.78125 19.84375 12.015625 19.328125 11.34375 18.65625 L 8.46875 21.53125 C 7.832031 22.167969 7.035156 22.554688 6.21875 22.59375 C 5.664063 22.621094 4.878906 22.503906 4.1875 21.8125 C 3.542969 21.167969 3.40625 20.414063 3.40625 19.90625 C 3.40625 19.050781 3.796875 18.199219 4.46875 17.53125 L 9.46875 12.5 C 10.726563 11.242188 12.640625 11.144531 13.75 12.25 C 14.386719 12.886719 15.429688 12.886719 16.0625 12.25 C 16.699219 11.613281 16.699219 10.574219 16.0625 9.9375 C 14.921875 8.796875 13.417969 8.21875 11.875 8.21875 Z "
        />
      </g>
    </svg>
  );
}

export default LinkThick;
